import React from "react";

export default function WarningIcon({ fill = "#333333", width = 50, height = 50, ...rest }) {
  return (
    <svg width={width} height={height} viewBox="0 0 190 190" fill="none" {...rest}>
      <path
        d="M83.4986 0.5C128.938 0.5 166 37.5622 166 83.0014C166 128.441 128.938 165.503 83.4986 165.503C38.0594 165.503 0.997285 128.441 0.997285 83.0014C0.997285 37.5622 38.0594 0.5 83.4986 0.5ZM83.4986 155.349C123.353 155.349 155.846 122.856 155.846 83.0014C155.846 43.1469 123.353 10.654 83.4986 10.654C43.6441 10.654 11.1513 43.1469 11.1513 83.0014C11.1513 122.856 43.6441 155.349 83.4986 155.349Z"
        fill={fill}
      />
      <path d="M78.4227 33.5002H88.5767V104.578H78.4227V33.5002Z" fill={fill} />
      <path d="M78 118H89V133H78V118Z" fill={fill} />
    </svg>
  );
}
